import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Learning from '../components/learning';

const Learnings = () => {
  return (
    <div className="learning">
      <h2>Ongoing Studies</h2>
      <StaticQuery
        query={learningQuery}
        render={data => {
          const projects = data.allMarkdownRemark.edges.map(edge => {
            return <Learning md={edge.node} key={edge.node.id} />;
          });
          return <>{projects}</>;
        }}
      />
    </div>
  );
};

export default Learnings;

const learningQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(learning)/.*\\\\.md$/" } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            displaydate
          }
        }
      }
    }
  }
`;
