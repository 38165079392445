import React from 'react';

const Education = () => {
  return (
    <div className="education">
      <h2>Education</h2>
      <h4>Bachelor of Computer Science</h4>
      <div>
        <p>University of Western Australia - 2014</p>
      </div>
    </div>
  );
};

export default Education;
