import React from 'react';

const Project = ({ md }) => {
  return (
    <div className="project">
      <h4>
        {md.frontmatter.title} | {md.frontmatter.displaydate}
      </h4>
      <div
        dangerouslySetInnerHTML={{ __html: md.html }}
        className="projectDescription"
      />
    </div>
  );
};

export default Project;
