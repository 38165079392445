import React from 'react';

import SEO from '../components/seo';
import Contact from '../components/contact';
import Jobs from '../components/jobs';
import Projects from '../components/projects';

import '../components/style.css';
import Education from '../components/education';
import Learnings from '../components/learnings';

const IndexPage = () => (
  <>
    <SEO title="Resume" />
    <div className="site">
      <Contact />
      <Jobs />
      <div class="sidebar">
        <Projects />
        <Education />
        <Learnings />
      </div>
    </div>
  </>
);

export default IndexPage;
