import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Project from '../components/project';

const Projects = () => {
  return (
    <div className="projects">
      <h2>Personal Projects</h2>
      <StaticQuery
        query={projectsQuery}
        render={data => {
          const projects = data.allMarkdownRemark.edges.map(edge => {
            return <Project md={edge.node} key={edge.node.id} />;
          });
          return <>{projects}</>;
        }}
      />
    </div>
  );
};

export default Projects;

const projectsQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(projects)/.*\\\\.md$/" } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            displaydate
          }
        }
      }
    }
  }
`;
