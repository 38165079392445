import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FiPhone, FiAtSign, FiLinkedin, FiGithub } from 'react-icons/fi';
import Image from '../components/image';

const Contact = () => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        return (
          <div className="contact">
            <Image />
            <h1> {data.site.siteMetadata.authorTitle} </h1>
            <h2> {data.site.siteMetadata.currentJobTitle} </h2>
            <div>
              <FiPhone />
              <a href="tel:+61403611355">+61 403 611 355</a>
            </div>
            <div>
              <FiAtSign />
              <a href="mailto:hello@alanseymour.dev">hello@alanseymour.dev</a>
            </div>
            <div>
              <FiGithub />
              <a href="https://github.com/alan-seymour">
                github.com/alan-seymour
              </a>
            </div>
            <div>
              <FiLinkedin />
              <a href="https://linkedin.com/in/alansey">
                linkedin.com/in/alansey
              </a>
            </div>
          </div>
        );
      }}
    />
  );
};

export default Contact;

const detailsQuery = graphql`
  query {
    site {
      siteMetadata {
        authorTitle
        currentJobTitle
      }
    }
  }
`;
