import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

const positionTitles = titles => {
  const joined = titles.reduce((p, v, i) => {
    if (i !== 0) {
      p.push(<FiChevronRight />);
    }
    p.push(<span>{v}</span>);
    return p;
  }, []);
  return joined;
};
const Job = ({ md }) => {
  return (
    <div className="job">
      <h3>{md.frontmatter.jobtitle}</h3>
      <h4>{positionTitles(md.frontmatter.positiontitle)}</h4>
      <h5>{md.frontmatter.displaydate}</h5>
      <div
        dangerouslySetInnerHTML={{ __html: md.html }}
        className="jobDescription"
      />
    </div>
  );
};

export default Job;
