import React from 'react';

const Learning = ({ md }) => {
  return (
    <div className="learning">
      <h4>{md.frontmatter.title}</h4>
      <div
        dangerouslySetInnerHTML={{ __html: md.html }}
        className="learningDescription"
      />
    </div>
  );
};

export default Learning;
