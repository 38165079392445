import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Job from '../components/job';

const Jobs = () => {
  return (
    <StaticQuery
      query={jobsQuery}
      render={data => {
        const jobs = data.allMarkdownRemark.edges.map(edge => {
          return <Job md={edge.node} key={edge.node.id} />;
        });
        return (
          <div className="jobs">
            <h1>Industry Experience</h1>
            {jobs}
          </div>
        );
      }}
    />
  );
};

export default Jobs;

const jobsQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(jobs)/.*\\\\.md$/" } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            jobtitle
            positiontitle
            displaydate
          }
        }
      }
    }
  }
`;
